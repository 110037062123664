<template>
    <div class="editor">
        <div>
            <p>Try edit the Matlab Source Code</p>
            <textarea class="matlab_text" :value="src_matlab" @input="update" />
        </div>
        <div>
            <p>Converted Python Script</p>
            <highlightjs
                class="python_code"
                language="python"
                :code="src_python"
            />
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash-es'
import hljsVuePlugin from '@highlightjs/vue-plugin'

export default {
    name: 'Converter',
    data: () => ({
        src_matlab: `disp(['hello', ','; 'world'  '!'])`,
        src_python: '',
    }),
    watch: {
        src_matlab: {
            immediate: true,
            handler(new_src, old_src) {
                if (
                    old_src === undefined ||
                    old_src.replace(/(\r\n|\n|\r)/gm, '') !==
                        new_src.replace(/(\r\n|\n|\r)/gm, '')
                ) {
                    this.convert()
                }
            },
        },
    },
    methods: {
        convert() {
            if (this.src_matlab.trim() === '') {
                this.src_python = 'Please input the matlab code ...'
                return
            }
            this.src_python = 'Starting converting, wait a moment...'
            fetch('api/convert', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    content: this.src_matlab,
                }),
            })
                .then((res) => res.json())
                .then((data) => {
                    this.src_python = data.content
                })
        },
        update: debounce(function (e) {
            this.src_matlab = e.target.value
        }, 1000),
    },
    components: {
        highlightjs: hljsVuePlugin.component,
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
    margin: 0;
}

.editor {
    height: 100vh;
    display: flex;
}

.editor div {
    width: 50%;
}

.matlab_text,
.python_code {
    overflow: auto;
    width: 100%;
    height: 100%;
    resize: none;
    box-sizing: border-box;
    padding: 0 20px;
}

.matlab_text {
    border: none;
    border-right: 1px solid #ccc;
    outline: none;
    background-color: #f6f6f6;
    font-size: 14px;
    font-family: 'Monaco', courier, monospace;
    padding: 20px;
}

.python_code {
    text-align: left;
}

code {
    color: #f66;
}
</style>
